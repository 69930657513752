<template>
  <div>
    <my-nav-bar
      title="特惠购下单"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div class="list">
        <div v-for="(goods, index) in list" :key="index" class="goods-container">
          <promote-sales-goods :goods="goods" @transout="handleClickTransout" />
        </div>
      </div>
    </div>
    <div class="fixed-bottom-btn">
      <van-button block square @click="handleConfirm">确认下单</van-button>
    </div>

    <van-dialog v-model="showQrcodeDialog" title="微信扫一扫(5分钟失效)">
      <div class="qrcode">
        <van-image class="qrcode-img" :src="qrcodeData" />
      </div>
    </van-dialog>

    <my-dialog v-model="showEmployeeDialog" title="选择转出员工" @confirm="showToQuantityDialog = true">
      <van-radio-group v-model="toEmployeeId" class="employee-container">
        <van-radio v-for="employee in employees" :key="employee.id" class="employee-item" :name="employee.id">{{ employee.name }}</van-radio>
      </van-radio-group>
    </my-dialog>

    <my-dialog v-model="showToQuantityDialog" title="输入转出数量" @confirm="handleTransout">
      <van-field v-model="toQuantity" label="数量" placeholder="请输入数量" type="number" clearable />
    </my-dialog>

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import myDialog from '@/components/my-dialog'
import PromoteSalesGoods from '@/components/PromoteSalesGoods'
import { getPromoteSalesPlanDetails, promoteSalesOrder, getEmployees, transout } from '@/api/promote-sales'
export default {
  components: { myNavBar, PromoteSalesGoods, myDialog },
  data() {
    return {
      list: [],
      qrcodeData: '',
      showQrcodeDialog: false,
      showEmployeeDialog: false,
      showToQuantityDialog: false,
      employees: [],
      toEmployeeId: null,
      toQuantity: '',
      fromPssDetail: null
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.beginLoad()
      getPromoteSalesPlanDetails().then(res => {
        this.endLoad()
        res.data.forEach(goods => {
          goods.buy = 0
        })
        this.list = res.data
      })
    },
    handleConfirm() {
      const buyList = this.list.filter(goods => goods.buy > 0)
      if (buyList.length === 0) {
        this.fail('下单数量不能为0')
        return
      }

      this.confirm('确定要下单吗？', '提示').then(() => {
        const buys = buyList.map(buyDetail => {
          return {
            promote_sales_details_id: buyDetail.id,
            buy: buyDetail.buy
          }
        })
        const data = { buys }
        this.beginLoad()
        promoteSalesOrder(data).then(res => {
          this.endLoad()
          this.qrcodeData = 'data:image/jpeg;base64,' + res.data
          this.showQrcodeDialog = true
        })
      })
    },
    handleClickTransout(pssDetail) {
      this.fromPssDetail = pssDetail
      if (this.employees.length === 0) {
        this.beginLoad()
        const params = {
          promote_sales_details_id: pssDetail.id
        }
        getEmployees(params).then(res => {
          this.endLoad()
          this.employees = res.data
          if (this.employees.length === 0) {
            this.fail('无可转出的员工')
            return
          } else {
            this.toEmployeeId = this.employees[0].id
          }
          this.showEmployeeDialog = true
        })
      } else {
        this.showEmployeeDialog = true
      }
    },
    handleTransout() {
      if (this.toQuantity <= 0) {
        this.fail('转出数量必须大于0')
        return
      }

      this.confirm('确定要转出吗？').then(() => {
        const data = {
          to_employee_id: this.toEmployeeId,
          to_quantity: this.toQuantity,
          promote_sales_details_id: this.fromPssDetail.id
        }
        this.beginLoad()
        transout(data).then(res => {
          this.success(res.msg)
          setTimeout(() => {
            this.getList()
          }, 1000)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
  margin-bottom: 50px;
}
.goods-container {
  margin-bottom: 10px;
}
.goods-container:last-child {
  margin-bottom: 0;
}
.qrcode {
  text-align: center;
  padding: 10px 0;
}
.qrcode-img {
  width: 200px;
  height: 200px;
}
.employee-container {
  padding: 20px;
  padding-top: 0;
}
.employee-item {
  padding-top: 10px;
}
</style>
