var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.thumb_image
    }
  })], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "size"
  }, [_vm._v("规格：1 * " + _vm._s(_vm.goods.size))]), _c('div', {
    staticClass: "quantity"
  }, [_vm._v("剩余：" + _vm._s(_vm.goods.quantity) + _vm._s(_vm.goods.least_unit))]), _c('div', {
    staticClass: "quantity"
  }, [_vm._v("已卖：" + _vm._s(_vm.goods.sales) + _vm._s(_vm.goods.least_unit))]), _c('div', {
    staticClass: "price"
  }, [_vm._v("单价：￥" + _vm._s(_vm.goods.price))]), _c('div', {
    staticClass: "stepper"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.goods.quantity === 0,
      "icon": "share-o",
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('transout', _vm.goods);
      }
    }
  }, [_vm._v("转出")]), _c('van-stepper', {
    attrs: {
      "button-size": "22",
      "min": "0",
      "max": _vm.goods.quantity,
      "default-value": "0"
    },
    model: {
      value: _vm.goods.buy,
      callback: function callback($$v) {
        _vm.$set(_vm.goods, "buy", $$v);
      },
      expression: "goods.buy"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }