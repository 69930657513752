<template>
  <div class="goods">
    <div class="image">
      <van-image :src="goods.thumb_image" />
    </div>
    <div class="info">
      <div class="name">{{ goods.name }}</div>
      <div class="size">规格：1 * {{ goods.size }}</div>
      <div class="quantity">剩余：{{ goods.quantity }}{{ goods.least_unit }}</div>
      <div class="quantity">已卖：{{ goods.sales }}{{ goods.least_unit }}</div>
      <!-- <div class="price">提成：￥{{ goods.commission }}/{{ goods.least_unit }}</div> -->
      <div class="price">单价：￥{{ goods.price }}</div>
      <div class="stepper">
        <van-button :disabled="goods.quantity === 0" icon="share-o" type="primary" size="mini" @click="$emit('transout', goods)">转出</van-button>
        <van-stepper v-model="goods.buy" button-size="22" min="0" :max="goods.quantity" default-value="0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoteSalesGoods',
  props: {
    goods: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color";
.goods {
  display: flex;
  padding: 10px;
  background-color: #fff;
}
.image, .image .van-image {
  width: 100px;
  height: 100px;
}
.info {
  margin-left: 10px;
  width: 100%;
}
.name {
  font-size: 15px;
  word-break: break-all;
}
.size, .storage, .quantity {
  font-size: 13px;
  color: #989898;
  margin-top: 2px;
}
.price {
  font-size: 13px;
  color: $amountRedColor;
  margin-top: 2px;
}
.stepper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
