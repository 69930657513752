var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "特惠购下单",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container"
    }, [_c('promote-sales-goods', {
      attrs: {
        "goods": goods
      },
      on: {
        "transout": _vm.handleClickTransout
      }
    })], 1);
  }), 0)]), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "block": "",
      "square": ""
    },
    on: {
      "click": _vm.handleConfirm
    }
  }, [_vm._v("确认下单")])], 1), _c('van-dialog', {
    attrs: {
      "title": "微信扫一扫(5分钟失效)"
    },
    model: {
      value: _vm.showQrcodeDialog,
      callback: function callback($$v) {
        _vm.showQrcodeDialog = $$v;
      },
      expression: "showQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "qrcode"
  }, [_c('van-image', {
    staticClass: "qrcode-img",
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)]), _c('my-dialog', {
    attrs: {
      "title": "选择转出员工"
    },
    on: {
      "confirm": function confirm($event) {
        _vm.showToQuantityDialog = true;
      }
    },
    model: {
      value: _vm.showEmployeeDialog,
      callback: function callback($$v) {
        _vm.showEmployeeDialog = $$v;
      },
      expression: "showEmployeeDialog"
    }
  }, [_c('van-radio-group', {
    staticClass: "employee-container",
    model: {
      value: _vm.toEmployeeId,
      callback: function callback($$v) {
        _vm.toEmployeeId = $$v;
      },
      expression: "toEmployeeId"
    }
  }, _vm._l(_vm.employees, function (employee) {
    return _c('van-radio', {
      key: employee.id,
      staticClass: "employee-item",
      attrs: {
        "name": employee.id
      }
    }, [_vm._v(_vm._s(employee.name))]);
  }), 1)], 1), _c('my-dialog', {
    attrs: {
      "title": "输入转出数量"
    },
    on: {
      "confirm": _vm.handleTransout
    },
    model: {
      value: _vm.showToQuantityDialog,
      callback: function callback($$v) {
        _vm.showToQuantityDialog = $$v;
      },
      expression: "showToQuantityDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "数量",
      "placeholder": "请输入数量",
      "type": "number",
      "clearable": ""
    },
    model: {
      value: _vm.toQuantity,
      callback: function callback($$v) {
        _vm.toQuantity = $$v;
      },
      expression: "toQuantity"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }